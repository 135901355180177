body{
  font-family: 'Lora', serif;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

/* Preloader */

#loading {
  position: fixed;
  z-index: 150;
  height: 100vh;
  width: 100%;
  background-image: url(./Images/preloader.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}

/* Hover Line */

.ls {
  position: relative;
  color: #1E1E1E;
  margin: 0.3rem 0rem;
  font-family: sans-serif;
  cursor: pointer;
}

.ls:hover {
  color: #1E1E1E;
}

.ls::after {
  position: absolute;
  top: 24px;
  left: 0px;
  width: 0%;
  height: 1px;
  content: '';
  background-color: #1E1E1E;
  transition: .2s linear;
}

.ls:hover::after {
  width: 80%;

}


/* Mobile Hover Line */

.lsm {
  position: relative;
  color: #1E1E1E;
  margin: 0.5rem 1rem;
  font-family: sans-serif;
  cursor: pointer;
}

.lsm:hover {
  color: #1E1E1E;
}

.lsm::after {
  position: absolute;
  top: 24px;
  left: 0px;
  width: 0%;
  height: 1px;
  content: '';
  background-color: #1E1E1E;
  transition: .2s linear;
}

.lsm:hover::after {
  width: 20%;
}

/* Keyframes */

@keyframes blackout {
  from{
    opacity: 0;
    height: 0rem;
  }
  to{
    opacity: 1;
    height: 10rem;
  }
}

@keyframes fileblackout {
  from{
    opacity: 0;
    height: 0rem;
  }
  to{
    opacity: 1;
    height: 20rem;
  }
}

@keyframes fileblackoutSecond {
  from{
    opacity: 0;
    height: 0rem;
  }
  to{
    opacity: 1;
    height: 25rem;
  }
}

@keyframes boxs {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


.blackoutClass{
  display: flex;
  height: 12rem;
  animation-name: blackout;
  animation-duration: 500ms;
  animation-timing-function:linear;
}

.fileblackoutClass{
  display: flex;
  animation-name: fileblackout;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
}

/* Media Qurey */

@media screen and (min-width: 1024px) {
  .fileblackoutClass{
    animation-name: fileblackoutSecond;
  }
}

.animatedClass{
  animation-name: boxs;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out
}